import React from 'react'
import Route from 'react-router-dom/Route'
import Switch from 'react-router-dom/Switch'
import Redirect from 'react-router-dom/Redirect'
import Notfound from './components/notfound/notfound'
import ResetPassword from './resetpassword'
import StoreObjectClass from './StoreObjectClass'
import HomePage from './pages/HomePage'
import { storesObject } from './services/StoresObject'
import { LandingPageHOC } from './components/login/landing-page/landing-page-container'
import { LoginPageHOC } from './components/login/login-panel/login-page-container'
import { MultiFactorAuthHOC } from './components/login/multi-factor-authentication/mfa-panel-container'
import './fonts/MyriadProRegular.otf'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const authed =
    localStorage.getItem('OTPAccessToken') ?? localStorage.getItem('userName')

  rest.path = rest.location.pathname

  let enviroment = (
    <Route
      {...rest}
      render={(props) =>
        authed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )

  return enviroment
}
const Routes = (props) => {
  return (
    <>
      <StoreObjectClass />
      <Switch>
        <Route exact path="/" component={LandingPageHOC} />
        <Route exact path="/login" component={LoginPageHOC} />
        <PrivateRoute
          path={`/new-layout`}
          children={(props) => (
            <HomePage storesObject={storesObject} props={props} />
          )}
        />
        <Route path="/setpassword/:token/:username" component={ResetPassword} />
        <PrivateRoute
          path="/setpassword/:token/:username"
          component={ResetPassword}
        />
        <PrivateRoute path="/login/mfa" component={MultiFactorAuthHOC} />
        <PrivateRoute component={Notfound} />
      </Switch>
    </>
  )
}

export default Routes
