import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { logoutHelpWidget } from '../components/FreshDeskWidget/freshdesk-widget'
import config, {
  annuitiesRoutePath,
  charitableRoutePath,
  forbiddenRoutePath,
  playerSupportRoutePath,
  welcomePageRoutePath,
} from '../config'
import { permissions } from '../constants'
import { checkRenderPermissions } from '../helpers'

export class AuthStore {
  rootStore = null

  // Password Login
  @observable passwordAuthErrorMessage = null
  @persist @observable accessToken = null
  @persist @observable refreshToken = null

  // OTP Verify
  @observable otpAuthErrorMessage = null
  @persist @observable otpAccessToken = null

  // User Details
  @persist @observable userId = null
  @persist @observable userName = null
  @persist @observable userFullName = null
  @persist @observable userRole = null
  @persist @observable userPolicies = null

  // In lieu of useFetch's isLoading flag,
  // this allows us to set spinners
  // for any calls made within this file.
  @observable requestLoading = false

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  /**
   *
   * @param userName
   * @param password
   */
  @action loginWithPassword(email, password, props) {
    let data = {
      username: email,
      password: Buffer.from(password).toString('base64'),
    }
    this.requestLoading = true
    fetch(`${config.SERVER_BASE_URL}/v1/auth/login`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error) {
          this.passwordAuthErrorMessage = res.error
        } else {
          this.otpAuthErrorMessage = null
          this.passwordAuthErrorMessage = null
          const { OTPAccessToken } = res
          if (OTPAccessToken) {
            localStorage.setItem('OTPAccessToken', OTPAccessToken)
            this.otpAccessToken = OTPAccessToken
            this.userName = email
            props.history.push('/login/mfa')
          } else {
            props.history.push('/')
          }
        }
      })
      .catch((error) => {
        this.passwordAuthErrorMessage = error.toString()
      })
      .finally(() => (this.requestLoading = false))
  }

  /**
   *
   * @param userName
   * @param TOTP
   */
  @action loginWithTOTP(OTP, props) {
    let data = {
      OTP: OTP,
    }
    this.requestLoading = true
    fetch(`${config.SERVER_BASE_URL}/v1/auth/login/mfa`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.getOTPAccessToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error) {
          this.otpAuthErrorMessage = res.error
        } else {
          localStorage.removeItem('OTPAccessToken')
          this.otpAuthErrorMessage = null
          this.passwordAuthErrorMessage = null
          props.history.push('/')
        }
      })
      .catch((error) => {
        this.otpAuthErrorMessage = error.toString()
      })
      .finally(() => {
        this.requestLoading = false
      })
  }

  /**
   *
   */
  @action requestOTP() {
    const token = localStorage.getItem('OTPAccessToken')

    fetch(`${config.SERVER_BASE_URL}/v1/auth/otp`, {
      credentials: 'include',
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res && res.error) {
          this.otpAuthErrorMessage = res.error
        } else if (res && res.message) {
          this.otpAuthErrorMessage = res.message
        }
      })
      .catch((error) => {
        this.errorMessage = error.toString()
      })
  }

  /**
   *
   */
  @action logout(props) {
    const username = this.getUserName()
    const url = `${config.SERVER_BASE_URL}/v1/auth/logout`
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          alert(res.error)
        } else {
          localStorage.clear()
          this.userId = null
          logoutHelpWidget()
          if (res.url) {
            // URL will be set in the response when SSO auth was used. Redirect to the Microsoft sign out screen.
            window.location.href = res.url
          } else {
            props.history.replace('/')
          }
        }
      })
      .catch((error) => {
        alert('Error:', error)
      })
  }

  @action handleUserInfo(res, props, navRedesignEnabled) {
    const { userInfo, policies } = res
    this.otpAuthErrorMessage = null
    this.passwordAuthErrorMessage = null

    const { firstname, lastname, userid, username } = userInfo
    this.userId = userid
    this.userName = username
    this.userFullName = `${firstname} ${lastname}`
    localStorage.setItem('userName', this.userName)
    localStorage.setItem('loggedInUserFullName', this.userFullName)
    localStorage.setItem('loggedInUserID', this.userId)
    localStorage.setItem('userpolicies', JSON.stringify(policies))
    this.afterLoginNavigation(props, navRedesignEnabled)
  }

  getOTPAccessToken() {
    return this.otpAccessToken
  }

  getUserName() {
    return this.userName
  }

  afterLoginNavigation(props, navRedesignEnabled) {
    // This is existing code, moved to a new method for easier re-use.
    const userPolicies = localStorage.getItem('userpolicies')
    const parsedPolicies = JSON.parse(userPolicies)

    if (navRedesignEnabled) {
      props.history.replace(welcomePageRoutePath)
    } else if (
      checkRenderPermissions(
        permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
        parsedPolicies
      )
    ) {
      props.history.replace(`${playerSupportRoutePath}/search-new`, {
        userName: this.userName,
      })
    } else if (
      checkRenderPermissions(
        permissions.CAN_SEE_CHARITABLE_GAMING,
        parsedPolicies
      )
    ) {
      props.history.replace(`${charitableRoutePath}`)
    } else if (
      checkRenderPermissions(permissions.CAN_SEE_ANNUITIES, parsedPolicies)
    ) {
      props.history.replace(`${annuitiesRoutePath}`)
    } else {
      // Since the Role has no permissions for RTC, CG and Annuities
      // delete the userPolicies so only the 'Logout' button is visible
      localStorage.setItem('userpolicies', JSON.stringify([]))
      props.history.replace(`${forbiddenRoutePath}`)
    }
  }
}
