import * as React from 'react'
import { getBlockClass } from '../../../utilities/helpers'
import { MassLotteryLogo } from '../../../icons/mass-lottery-logo'
import './styles.scss'

const ROOT_CLASS = 'login-landing-page'

export const LandingPageUI = (props: React.PropsWithChildren<{}>) => {
  return (
    <div className={ROOT_CLASS}>
      <div className={getBlockClass(ROOT_CLASS, 'boston-background')} />
      <div className={getBlockClass(ROOT_CLASS, 'panel')}>
        <MassLotteryLogo />
        {props.children}
      </div>
    </div>
  )
}
