import * as React from 'react'
import {
  useFetchGetNontaxableEmailCount,
  useFetchSendNontaxableEmails
} from '../../../../api/promotions-fetcher/use-fetch-winners-management'
import { SendNontaxableEmailsModal } from './send-nontaxable-emails-ui'
import { BannerContext } from '../../../../context/BannerContext'

export type SendNontaxableEmailsModalContainerProps = {
  drawingId: string
  closeModal: () => void
  fetchDrawings: () => Promise<void>
}

export const SendNontaxableEmailsModalContainer = (props: SendNontaxableEmailsModalContainerProps) => {

  const {data: emailCountResponse, error: emailCountError, fetchNontaxableEmailCount} = useFetchGetNontaxableEmailCount()
  const {error: sendEmailsError, requestSuccessful: sendEmailsSuccess,  fetchSendEmails} = useFetchSendNontaxableEmails()

  const bannerContext = React.useContext(BannerContext)

  React.useEffect(() => {
    if(!emailCountResponse){
      fetchNontaxableEmailCount(props.drawingId)
    }
  }, [emailCountResponse])

  React.useEffect(() => {
    if( emailCountError){
      props.closeModal()
      bannerContext.setBannerInfo({
        message: emailCountError,
        error: true
      })
    }

    if( sendEmailsError){
      props.closeModal()
      bannerContext.setBannerInfo({
        message: sendEmailsError,
        error: true
      })
    }
  }, [sendEmailsError, emailCountError])

  React.useEffect(() => {
    if(sendEmailsSuccess){
      props.closeModal()
      props.fetchDrawings()

      bannerContext.setBannerInfo({
        message: `${emailCountResponse?.numEmails} non-taxable prize emails have been queued for sending`,
        error: false
      })
    }

  }, [sendEmailsSuccess])

  return (
    <SendNontaxableEmailsModal
      emailCount={emailCountResponse?.numEmails}
      drawingId={props.drawingId}
      sendEmails={fetchSendEmails}
      closeModal={props.closeModal}
    />
  )

}