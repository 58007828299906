import * as React from 'react'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../../utilities/helpers'
import {
  NavModule,
  checkCategoryPermissions,
  checkLinkPermissions,
} from '../helpers'
import './styles.scss'

const MENU_CLASS = `navigation-menu`
const CATEGORY_CLASS = getBlockClass(MENU_CLASS, 'category')
const LINKS_CLASS = getBlockClass(CATEGORY_CLASS, 'links')

type NavigationMenuProps = {
  isOpen: boolean
  currentPath: string
  module?: NavModule
  closeMenu: () => void
  navigateToPage: (path: string) => void
  handleLogout: () => void
}

export const NavigationMenu = (props: NavigationMenuProps) => {
  return (
    <div
      className={joinClasses([
        MENU_CLASS,
        getModifierClass(MENU_CLASS, 'open', props.isOpen),
      ])}
    >
      {props.module?.categories
        .filter((category) => checkCategoryPermissions(category))
        .map((category) => {
          return (
            <div className={CATEGORY_CLASS} key={category.title}>
              <div className={getBlockClass(CATEGORY_CLASS, 'label')}>
                {category.title}
              </div>
              <div className={LINKS_CLASS}>
                {category.links
                  .filter((link) => checkLinkPermissions(link))
                  .map((link) => (
                    <button
                      key={`${link.displayName}-button`}
                      className={getBlockClass(LINKS_CLASS, 'link')}
                      aria-label={`Go to ${link.displayName}`}
                      onClick={() => {
                        if (link.destination === 'logout') {
                          props.handleLogout()
                        } else {
                          props.navigateToPage(link.destination)
                          props.closeMenu()
                        }
                      }}
                      disabled={props.currentPath === link.destination}
                    >
                      {link.displayName}
                    </button>
                  ))}
              </div>
            </div>
          )
        })}
    </div>
  )
}
