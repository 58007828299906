export const permissions = {
  PROCESS_CLAIMS: 'Can process Claims',
  UPDATE_CLAIMS: 'Can update Claims',
  DO_CLAIM_RESEARCH: 'Can do Claim Research',
  CAN_SEE_REJECT_CLAIMS: 'Can see Rejected Claims',
  CAN_SEE_REJECT_CLAIMS_DETAILS: 'Can see Rejected Claims Details',
  CAN_SEE_CHECK_REQUEST_CLAIMS: 'Can see Check Request',
  SEE_CLAIM_REPORTS_QUERIES: 'Can see Claim Reports/Queries',
  SEE_PLAYER_SUPPORT: 'Can see Player Support*',
  SEE_PLAYER_VERIFICATION: 'Can see Player Verification Services',
  RESET_PLAYER_PASSWORD: 'Can reset Player password',
  UNLOCK_PLAYER_ACCOUNT: 'Can unlock Player account',
  ENABLE_PLAYER_SCANS: 'Can enable Player scans',
  SEE_PLAYER_SERVICE_REPORTS: 'Can see Player Service Reports',
  UPDATE_PLAYER: 'Can Update Player Profile',
  SEE_CLAIM_SUPPORT: 'Can see Claim Support*',
  CAN_SEE_TICKET_STATUS_CLAIMED_CLAIM_REPORT:
    'Can see Ticket Status Claimed Claim Report',
  CAN_SEE_DRAW_DATE_7_DAYS_CLAIM_REPORT:
    'Can see Draw Date 7 Days Claim Report',
  CAN_SEE_DRAW_DATE_30_DAYS_CLAIM_REPORT:
    'Can see Draw Date 30 Days Claim Report',
  CAN_SEE_CUSTOM_CLAIM_REPORT: 'Can see Custom Claim Report',
  CAN_SEE_SYSTEM_SUPPORT: 'Can see System*',
  CAN_SEE_PLAYER_EVENT_HISTORY: 'Can see Player Event History',
  CAN_SEE_USER_AUDIT_ACTIONS: 'Can see User Audit Actions',
  CAN_ACCESS_SECURITY_MODULE: 'Can access Security Module*',
  CAN_ACCESS_FREQUENT_CASHER_MODULE: 'Can access Frequent Casher Module',
  CAN_ACCESS_FINANCE_PAYMENTS_MODULE: 'Can access Finance & Payments module*',
  CAN_ACCESS_ANALYTICS_MODULE: 'Can access Analytics module*',
  CAN_SEE_ADMIN_MENU: 'Can See Administration Menu*',
  CAN_ACCESS_USER_MANAGEMENT: 'Can access User Management*',
  CAN_CREATE_USER_ACCOUNT: 'Can create User account',
  CAN_EDIT_USER_ACCOUNT: 'Can edit User account',
  CAN_RESET_USER_PASSWORD: 'Can reset User password',
  CAN_DEACTIVATE_USER_ACCOUNT: 'Can deactivate User account',
  CAN_REACTIVATE_USER_ACCOUNT: 'Can reactivate User account',
  CAN_DELETE_USER_ACCOUNT: 'Can delete User account',
  CAN_ACCESS_ROLE_MANAGEMENT: 'Can access Role Management*',
  CAN_UNLOCK_USER_ACCOUNT: 'Can Unlock User account',
  CAN_ADD_ROLE: 'Can add Role',
  CAN_EDIT_ROLE: 'Can edit Role',
  CAN_DELETE_ROLE: 'Can delete Role',
  CAN_UPDATE_PLAYER_SSN: 'Can update Player SSN',
  CAN_CONFIRM_PLAYER_EMAIL: 'Can confirm Player Email',
  CAN_ACCESS_SETTINGS: 'Can access Settings*',
  CAN_SEE_MFA_BYPASS_RULES: 'Can see MFA Bypass Rules',
  CAN_EDIT_MFA_BYPASS_RULES: 'Can edit MFA Bypass Rules',
  CAN_SEE_KNOWLEDGE_BASE: 'Can See Knowledge Base*',
  CAN_VIEW_DOWNLOAD_ADMINISTRATOR_USER_GUIDE:
    'Can view/download Administrator User Guide',
  CAN_VIEW_DOWNLOAD_PLAYER_SUPPORT_USER_GUIDE:
    'Can view/download Player Support User Guide',
  CAN_VIEW_DOWNLOAD_ANDROID_APP_USER_GUIDE:
    'Can view/download Android App User Guide',
  CAN_VIEW_DOWNLOAD_TROUBLESHOOTING_GUIDE:
    'Can view/download Troubleshooting Guide',
  CAN_VIEW_DOWNLOAD_TECHNICAL_DOCUMENTATION:
    'Can view/download Technical Documentation',
  CAN_SEE_CLAIMS: 'Can see Claims',
  CAN_SEE_CLAIM_DETAILS: 'Can see Claim Details',
  CAN_SEE_CLAIM_W2G: 'Can see Claim W2G Form',
  CAN_RESUBMIT_PAYMENT: 'Can Resubmit Payment',
  CAN_SEE_ACH_ACTIVITY: 'Can see ACH Activity',
  CAN_SEE_DAILY_RECONCILIATION: 'Can see Daily Reconciliation Report',
  CAN_SEE_BATCH_REJECT_FILE_DETAILS: 'Can see Batch/Reject file details',
  CAN_SEE_BATCH_MASKED_DETAIL: 'Can see Batch Masked PII details',
  CAN_SEE_REJECT_MASKED_DETAIL: 'Can see Reject Masked PII details',
  CAN_SEE_CLAIM_TRANSACTION_DETAILS:
    'Can see Claim Transaction History details',
  CAN_SEE_CLAIM_TRANSACTION_PII:
    'Can see Claim Transaction Account Information',
  CAN_SEE_E_SIGNATURE: 'Can see E-Signature',
  CAN_SEE_PLAYER_SUPPORT_NEW: 'Can see Player Support New',
  CAN_SEE_TICKETS_NEW: 'Can See Ticket Search',
  CAN_SEE_ACTIONS: 'Can See Actions',
  CAN_SEE_CLAIM_ACTIVITY: 'Can See Claims Activity',
  CAN_SEE_SESSION_ACTIVITY: 'Can See Session Activity',
  CAN_SEE_PLAYER_DOCUMENT_QUEUE: 'Can see Player Document Queue',
  CAN_ENABLE_DISABLE_ACCOUNT_STATUS: 'Can enable/disable account status',
  CAN_SEE_ANNUITIES: 'Can See Annuities',
  CAN_SEE_LOCATE_PAYMENT_CREATE_ANNUITY:
    'Can See Locate Payment/Create Annuity',
  CAN_SEE_CREATE_EXCEPTION_ANNUITY: 'Can See Create Exception Annuity',
  CAN_SEE_MANAGE_ANNUITIY: 'Can See Manage Annuity',
  CAN_SEE_MANAGE_PAYEE: 'Can See Manage Payee',
  CAN_SEE_MANAGE_TIME_PYMT_AUTH: 'Can See Time Payment Authorization',
  CAN_SEE_TIME_PYMT_AUTH_SEARCH: 'Can See Time Payment Authorization Search',
  CAN_SEE_TIME_PYMT_AUTH_HISTORY: 'Can See Time Payment Authorization History',
  CAN_SEE_TIME_PYMT_AUTH_LIST: 'Can See Time Payment Authorization List',
  CAN_SEE_TIME_PYMT_AUTH_DETAILS: 'Can See Time Payment Authorization Details',
  CAN_SEE_SSN: 'Can see SSN',
  CAN_SEE_CHARITABLE_GAMING: 'Can See Charitable Gaming',
  CAN_MANAGE_LICENSE_PROFILES: 'Can Manage License Profiles',
  CAN_CREATE_LICENSE_PROFILES: 'Can Create License Profiles',
  CAN_VIEW_LICENSE_DETAILS: 'Can View License details',
  CAN_UPDATE_LICENSE_DETAILS: 'Can Update License details',
  CAN_REACTIVATE_LICENSE: 'Can Reactivate License',
  CAN_RENEW_LICENSES: 'Can Renew Licenses',
  CAN_DOWNLOAD_LICENSE_FILE: 'Can Download License File',
  CAN_MANAGE_PERMITS: 'Can Manage Permits',
  CAN_VIEW_PERMIT_DETAILS: 'Can View Permit Details',
  CAN_CREATE_PERMIT_PROFILES: 'Can Create Permit Profiles',
  CAN_UPDATE_PERMIT_PROFILES: 'Can Update Permit Profiles',
  CAN_MANAGE_TAX_FORMS: 'Can Manage Tax Forms',
  CAN_MANAGE_PULL_TABS: 'Can Manage Pull Tabs',
  CAN_VIEW_EFT_DETAILS: 'Can View EFT details',
  CAN_UPDATE_EFT_DETAILS: 'Can Update EFT details',
  CAN_SEE_PULL_TAB_GAMES: 'Can See Pull Tab Games',
  CAN_CREATE_PULL_TAB_GAMES: 'Can Create Pull Tab Games',
  CAN_UPDATE_PULL_TAB_GAMES: 'Can Update Pull Tab Games',
  CAN_SEE_PULL_TAB_ORDERS: 'Can See Pull Tab Orders',
  CAN_SEE_PULL_TAB_ORDER_DETAILS: 'Can See Pull Tab Order Details',
  CAN_CREATE_PULL_TAB_ORDERS: 'Can Create Pull Tab Orders',
  CAN_RETURN_PULL_TAB_ORDERS: 'Can Return Pull Tab Orders',
  CAN_SEE_PULL_TAB_INVENTORY: 'Can See Pull Tab Inventory',
  CAN_ADD_NEW_PULL_TAB_INVENTORY: 'Can Add New Pull Tab Inventory',
  CAN_TRANSFER_PULL_TAB_INVENTORY: 'Can Transfer Pull Tab Inventory',
  CAN_SEE_PULL_TAB_STOCK_INVENTORY: 'Can See Pull Tab Stock Inventory',
  CAN_SEE_WEEKLY_BEANO_TAX_TAB: 'Can See Weekly Beano Tax Forms Tab',
  CAN_SEE_WEEKLY_BEANO_FORM: 'Can See Weekly Beano Form',
  CAN_SEE_ANNUAL_BEANO_TAX_TAB: 'Can See Annual Beano Tax Forms Tab',
  CAN_SEE_ANNUAL_BEANO_FORM: 'Can See Annual Beano Form',
  CAN_SEE_CASINO_FORM: 'Can See Casino Tax Form',
  CAN_SEE_CASINO_TAX_FORM_TAB: 'Can See Casino Tax Forms Tab',
  CAN_SEE_RAFFLE_FORM: 'Can See Raffle Tax Form',
  CAN_SEE_RAFFLE_TAX_FORM_TAB: 'Can See Raffle Tax Forms Tab',
  CAN_SEE_DELINQUENT_REPORTS_TAB: 'Can See Delinquent Reports Tab',
  CAN_SEE_CHARGE_MANAGEMENT: 'Can See Charge Management',
  CAN_SEE_LICENSEE_CHARGES: 'Can See Licensee Charges',
  CAN_SEE_EFT_LEDGER: 'Can See EFT Ledger',
  CAN_SEE_PERMIT_HOLDER_CHARGES: 'Can See Permit Holder Charges',
  CAN_MARK_CHECK_RECEIVED: 'Can Mark Check Received',
  CAN_RUN_EFT_SWEEP: 'Can Run EFT Sweep',
  CAN_SCHEDULE_AND_HOLD_CHARGES: 'Can Schedule/Hold Charges',
  CAN_SETTLE_AND_REJECT_CHARGES: 'Can Settle/Reject Charges',
  CAN_UPDATE_PAYMENT_METHOD: 'Can Update Payment Method',
  CAN_VIEW_EXPENSE_DETAILS: 'Can View Expense Details',
  CAN_SEE_EFT_CHARGE_DETAILS: 'Can See EFT Charge Details',
  CAN_SEE_LICENSEE_CHARGE_DETAILS: 'Can See Licensee Charge Details',
  CAN_SEE_PERMIT_HOLDER_CHARGE_DETAILS: 'Can See Permit Holder Charge Details',
  GET_CHARGE_DETAILS: 'Get Charge Details',
  CAN_SEE_CHARITABLE_GAMING_REPORTS: 'Can See Charitable Gaming Reports',
  CAN_SEE_WEEKLY_BEANO_REPORTS: 'Can See Weekly Beano Reports',
  CAN_SEE_ANNUAL_BEANO_REPORTS: 'Can See Annual Beano Reports',
  CAN_SEE_RAFFLE_CASINO_REPORTS: 'Can See Raffle/Casino Reports',
  CAN_SEE_OFFSETS_INQUIRY_SERVICE: 'Can See Offsets Inquiry Service',
  CAN_SEE_OFFSETS_REPORTING: 'Can See Offsets Reporting',
  CAN_SEE_OFFSETS_BY_ACH: 'Can See Offsets by ACH',
  CAN_SEE_GLOBAL_PAYMENTS_MODULE: 'Can See Global Payment Modules',
  CAN_SEE_GLOBAL_PAYMENTS_SEARCH: 'Can See Global Payments Search',
  CAN_SEE_GLOBAL_PAYMENT_DETAILS: 'Can See Global Payments Details',
  CAN_SEE_MONTHLY_USERS: 'Can See Monthly Users',
  CAN_SEE_YEAR_END_REPORTS: 'Can See Year End Reports',
  CAN_DOWNLOAD_YEAR_END_REPORTS: 'Can Download Year End Reports',
  CAN_SEE_QUARTERLY_TAX_TOTAL_REPORTS: 'Can See Quarterly Tax Total Reports',
  CAN_SEE_W2G_QUEUE: 'Can See W-2G Queue',
  CAN_PRINT_W2G_QUEUE: 'Can Print W-2G Queue',
  CAN_SEE_W2G_PRINT_HISTORY: 'Can See W-2G Print History',
  CAN_SEE_W2G_PRINT_DETAILS: 'Can See W-2G Print Details',
  CAN_EXPORT_W2G_PRINT_DETAILS: 'Can Export W-2G Print Details',
  CAN_VOID_PAYMENT_AND_REISSUE_W2G: 'Can Void Payment and Re-issue W-2G',
  CAN_VOID_PAYMENTS: 'Can Void Payments',
  CAN_REISSUE_W2G: 'Can Re-issue W-2G',
  CAN_CREATE_5754_SPLIT: 'Can Create 5754 Split',
  CAN_SEE_CHECK_RUN_QUEUE: 'Can See Check Run Queue',
  CAN_SEE_CHECK_RUN_HISTORY: 'Can See Check Run History',
  CAN_INITIATE_CHECK_RUN: 'Can Initiate Check Run',
  CAN_VIEW_CHECK_RUN_REPORTS: 'Can View Check Run Reports',
  CAN_SEARCH_FUTURE_PAYMENTS: 'Can Search Future Payments',
  CAN_UPDATE_FUTURE_PAYMENT_STATUS: 'Can Update Future Payment Status',
  CAN_SEE_FUTURE_PAYMENT_DETAILS: 'Can See Future Payment Details',
  CAN_UPDATE_FUTURE_PAYMENT_STATUS: 'Can Update Future Payment Status',
  CAN_VIEW_PAST_CHECK_RUN_PAYMENTS: 'Can View Past Check Run Payments',
  CAN_SEE_PENDING_REISSUES: 'Can See Pending Reissues',
  CAN_SEE_REISSUE_HISTORY: 'Can See Reissue History',
  CAN_SEE_5754_SPLIT_REPORTS: 'Can See 5754 Split Reports',
  CAN_DOWNLOAD_5754_SPLIT_REPORTS: 'Can Download 5754 Split Reports',
  CAN_VOID_AND_REISSUE_PAYMENT: 'Can Void and Reissue Payment',
  CAN_UPDATE_TAX_ADJUSTMENT_STATUS: 'Can Update Tax Adjustment Status',
  CAN_DOWNLOAD_W2G_PRINT_REPORT: 'Can Download W2G Print Report',
  CAN_UPDATE_FUTURE_PAYMENT_DETAILS: 'Can Update Future Payment Details',
  CAN_DELETE_FUTURE_PAYMENT: 'Can Delete Future Payment',
  CAN_SEE_CHECK_REGISTER_REPORT: 'Can See Check Register Report',
  CAN_SEE_CHECK_LOAD_REPORT: 'Can See Check Load Report',
  CAN_SEE_TICKET_SCANNING_ACTIVITY: 'Can see Ticket Scanning Activity',
  // Coupon Campaign Permissions
  CAN_READ_COUPON_CAMPAIGNS: 'Can Read Coupon Campaigns',
  CAN_WRITE_COUPON_CAMPAIGNS: 'Can Write Coupon Campaigns',
  CAN_END_COUPON_CAMPAIGNS: 'Can End Coupon Campaigns',
  CAN_ASSIGN_PLAYER_COUPONS: 'Can Assign Coupons',
  // Promotion Entries Permissions
  CAN_READ_PROMOTION_ENTRIES: 'Can Read Promotion Entries',
  CAN_DELETE_PROMOTION_ENTRIES: 'Can Delete Promotion Entries',
  // Promotion Campaign Permissions
  CAN_READ_PROMOTION_CAMPAIGNS: 'Can Read Promotion Campaigns',
  CAN_ADMIN_PROMOTION_CAMPAIGNS: 'Can Admin Promotion Campaigns',
  CAN_SCHEDULE_PROMOTION_CAMPAIGN_DRAWING:
    'Can Schedule Promotion Campaign Drawing',
  // Playslips Permissions
  CAN_ADMIN_PROMOTIONAL_MESSAGES: 'Can Admin Promotional Messages',
  CAN_READ_PROMOTIONAL_MESSAGES: 'Can Read Promotional Messages',
  CAN_READ_PLAYSLIPS_STEP_DOWN: 'Can Read Playslips Step Down',
  CAN_ADMIN_PLAYSLIPS_STEP_DOWN: 'Can Admin Playslips Step Down',
  // Winners Management Permissions
  CAN_ADD_PROMOTION_CAMPAIGN_WINNERS: 'Can Add Promotion Campaign Winners',
  CAN_ADMIN_WINNERS_MANAGEMENT: 'Can Admin Winners Management',
  CAN_READ_WINNERS_MANAGEMENT: 'Can Read Winners Management',
  CAN_SEND_NONTAXABLE_EMAILS: 'Can Send Nontaxable Emails',
  // Promotion Reporting Permissions
  CAN_READ_PROMOTION_REPORTS: 'Can Read Promotion Reports',
  //Agent 1099 Permissions
  CAN_MANAGE_1099_QUEUE: 'Can See 1099 Queue',
  CAN_PRINT_1099_QUEUE: 'Can Print 1099 Queue',
  CAN_SEE_1099_PRINT_HISTORY: 'Can See 1099 Print History',
  CAN_GENERATE_1099_REPORTS: 'Can Generate 1099 Reports',
  CAN_SEE_1099_REPORTS: 'Can See 1099 Reports',
}

export const timeOfDayFormat = 'HH:mm'

export const KEY_CODES = {
  ENTER: 13,
  SPACE: 32,
  UNKNOWN_KEY: 0,
}
