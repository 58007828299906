import * as React from 'react'
import { getBlockClass } from '../../../../utilities/helpers'
import { LoadingDots } from '../../../CommonComponents/loading-dots/loading-dots-ui'
import { GenericPopupModal } from '../../../GenericPopupModal/generic-popup-modal-ui'
import './styles.scss'

export type SendNontaxableEmailsModalUIProps = {
  emailCount?: number
  drawingId: string
  sendEmails: (drawingId: string, count: number) => Promise<void>
  closeModal: () => void
}

const ROOT_CLASS = 'send-nontaxable-emails-modal'

export const SendNontaxableEmailsModal = (
  props: SendNontaxableEmailsModalUIProps
) => {
  async function sendEmails() {
    await props.sendEmails(props.drawingId, props.emailCount!)
  }

  return (
    <GenericPopupModal isOpen closeModal={props.closeModal} roundedEdges>
      <div className={ROOT_CLASS}>
        {!props.emailCount && <LoadingDots id="loading-dots" />}
        {props.emailCount && (
          <div className={getBlockClass(ROOT_CLASS, 'modal-container')}>
            <p className={getBlockClass(ROOT_CLASS, 'message')}>
              Are you sure you would like to send {props.emailCount} non-taxable
              emails?
            </p>
            <button
              className={getBlockClass(ROOT_CLASS, 'confirm-button')}
              onClick={sendEmails}
            >
              Confirm
            </button>
            <button
              className={getBlockClass(ROOT_CLASS, 'go-back-button')}
              onClick={props.closeModal}
            >
              Go Back
            </button>
          </div>
        )}
      </div>
    </GenericPopupModal>
  )
}
