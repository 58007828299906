import * as React from 'react'

type SettingsIconProps = {
  color: string
}

export const SettingsIcon = (props: SettingsIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4269 3.37969C16.1241 3.58957 16.7857 3.88175 17.4003 4.24492L19.7227 1.92251L23.1513 5.35108L20.7603 7.74213C21.0981 8.37039 21.3636 9.0434 21.5453 9.75H24.8281V14.5987L21.4474 14.5987C21.2375 15.2955 20.9455 15.9566 20.5825 16.5709L22.9047 18.8931L19.4761 22.3216L17.0852 19.9307C16.5614 20.2124 16.0064 20.4438 15.4269 20.6182V23.9999H10.5781V20.7153C9.8721 20.5335 9.19959 20.2682 8.57178 19.9306L6.18073 22.3216L2.75216 18.8931L5.07457 16.5707C4.71166 15.9565 4.41964 15.2954 4.20979 14.5987L0.828125 14.5987L0.828125 9.75H4.11187C4.29362 9.04349 4.55901 8.37055 4.89684 7.74235L2.50557 5.35108L5.93414 1.92251L8.25668 4.24504C8.97274 3.82192 9.75252 3.49516 10.5781 3.28261V0L15.4269 2.11946e-07V3.37969ZM12.8286 15.3421C14.675 15.3421 16.1718 13.8453 16.1718 11.999C16.1718 10.1526 14.675 8.65581 12.8286 8.65581C10.9822 8.65581 9.48546 10.1526 9.48546 11.999C9.48546 13.8453 10.9822 15.3421 12.8286 15.3421Z"
        fill={props.color}
      />
    </svg>
  )
}
