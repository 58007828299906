import * as React from 'react'
import { getBlockClass } from '../../utilities/helpers'
import './styles.scss'

type WelcomePageUIProps = {
  firstName?: string
}

const ROOT_CLASS = 'welcome-page'

enum TimeOfDay {
  MORNING = 'Morning',
  AFTERNOON = 'Afternoon',
  EVENING = 'Evening',
}

export const WelcomePageUI = (props: WelcomePageUIProps) => {
  const currentHour = new Date().getHours()

  let currentTimeOfDay = TimeOfDay.EVENING

  const morningHour = 5
  const afternoonHour = 12
  const eveningHour = 18

  if (currentHour >= morningHour && currentHour < afternoonHour) {
    currentTimeOfDay = TimeOfDay.MORNING
  } else if (currentHour >= afternoonHour && currentHour < eveningHour) {
    currentTimeOfDay = TimeOfDay.AFTERNOON
  }

  return (
    <div className={ROOT_CLASS}>
      <h1 className={getBlockClass(ROOT_CLASS, 'header')}>
        Good {currentTimeOfDay}
        {props.firstName ? `, ${props.firstName}` : ''}
      </h1>
      <p className={getBlockClass(ROOT_CLASS, 'welcome-message')}>
        Welcome to the Back Office
      </p>
    </div>
  )
}
