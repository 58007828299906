import React, { useEffect, useState } from "react";
import stylesMessageBox from "../components/TicketSearchComponents/TicketInformation/css/stylesMessageBox.module.css";
import config from "../config";
import { keyPressedType } from "../services/Commons";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const AppLogout = ({children}) => {
    let timer;
    let timerLogOut;
    const [showPopup, setShowPop] = useState(false);

    useEffect(() => {
        addMaxAndMinTimer()
    }, []);

    const addMaxAndMinTimer = () => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetMinTimer()
                handleMinTimer()
            });
        });
    };

    const resetMinTimer = () => {
        if (timer) {clearTimeout(timer);}
    };

    const handleMinTimer = () => {
        timer = setTimeout(() => {
            resetMinTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetMinTimer);
            });
            popUpAction();
        }, 14 * 60 * 1000);
        // 1000 * 60 * 60 - 1 hour
        // 10000 - 10 sec
    };

    const popUpAction = () => {
        // console.log("triggered popUpAction")
        const pathName = window.location.hash
        // alert("pathName 1 -- " + window.location.href + "--" + pathName)
        if ((pathName === "#/") || (pathName === "#/login")) {
            return
        }
        if (pathName !== "#/") {
            setShowPop(true)
            handleMaxTimer()
        }
    };

    const resetMaxTimer = () => {
        if (timerLogOut) {clearTimeout(timerLogOut);}
    };

    const handleMaxTimer = () => {
        timer = setTimeout(() => {
            resetMaxTimer();
            logOutAction();
        }, 60 * 1000);
        // 1000 * 60 * 60 - 1 hour
        // 10000 - 10 sec
    };

    const logOutAction = () => {
        // console.log("triggered logOutAction")
        setShowPop(false)
        fetch(`${config.SERVER_BASE_URL}/v1/auth/logout`, {
            credentials: 'include',
            method: 'POST'
        })
            .finally(() => {
                localStorage.clear();
                window.location = window.location.pathname;
            })
    };

    return (
        <>
            {showPopup &&
                <div className={stylesMessageBox['window-container']}>
                    <div tabIndex={0} id='manually-approve-kyc-container'
                         className={stylesMessageBox['manually-approve-kyc-container']}>
                        <div tabIndex={0} className={stylesMessageBox['manually-approve-kyc-text']}>Session Expiration
                        </div>
                        <div tabIndex={0}
                             className={stylesMessageBox['manually-approve-kyc-message']}>You will be log out in 60
                            sec.<br/>
                            Click OK to continue working.
                        </div>
                        <div id="countdown"></div>
                        <div role='button'
                             tabIndex={0}
                             onKeyPress={(e: any) => {
                                 if (keyPressedType(e) === 13 || keyPressedType(e) === 0 || keyPressedType(e) === 32) {
                                     setShowPop(false)
                                 }
                             }
                             }
                             onClick={() => {
                                 setShowPop(false)
                                 addMaxAndMinTimer()
                                 resetMaxTimer();
                                 handleMaxTimer()
                             }}
                             className={stylesMessageBox['manually-approve-kyc-button-yes']}>
                            <div className={stylesMessageBox['manually-approve-kyc-yes-text']}>Ok</div>
                        </div>
                    </div>
                </div>}
            {children}
        </>
    );
};

export default AppLogout;


