import * as React from 'react'
import { NavigationBarUI } from './navigation-bar-ui'
import { useHistory } from 'react-router-dom'
import { navigationModules } from './helpers'
import { clearPageMessageDefault } from '../CommonComponents/PageMessageCommonComponent'

type NavigationBarContainerProps = {
  storesObject: any
}

export const NavigationBarContainer = (props: NavigationBarContainerProps) => {
  const history = useHistory()

  const navigateToPage = (path: string) => history.push(path)

  const handleLogout = () => {
    clearPageMessageDefault()
    props.storesObject.playerStore.reset()
    props.storesObject.claimStore.reset()
    props.storesObject.authStore.logout({ history })
  }

  return (
    <NavigationBarUI
      navigateToPage={navigateToPage}
      modules={navigationModules}
      handleLogout={handleLogout}
    />
  )
}
