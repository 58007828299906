import * as React from 'react'

type SearchIconProps = {
  color: string
}

export const SearchIcon = (props: SearchIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.36364 13.7273C11.3259 13.7273 13.7273 11.3259 13.7273 8.36364C13.7273 5.40138 11.3259 3 8.36364 3C5.40138 3 3 5.40138 3 8.36364C3 11.3259 5.40138 13.7273 8.36364 13.7273ZM8.36364 16.7273C12.9827 16.7273 16.7273 12.9827 16.7273 8.36364C16.7273 3.74453 12.9827 0 8.36364 0C3.74453 0 0 3.74453 0 8.36364C0 12.9827 3.74453 16.7273 8.36364 16.7273Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0001 24.7259L13.0911 13.8168L15.9195 10.9884L26.8286 21.8975L24.0001 24.7259Z"
        fill={props.color}
      />
    </svg>
  )
}
