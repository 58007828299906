import { fetchAndActivate, getAll } from 'firebase/remote-config'
import * as React from 'react'
import FirebaseService from '../services/firebase'
import {
  BackOfficeFeatureFlag,
  BackOfficeRemoteConfig,
} from '../services/firebase/types'
import { snakeCaseToCamelCase } from '../util'

const featureFlagDefaults: BackOfficeFeatureFlag = {
  ssoAuthEnabled: false,
  legacyLoginEnabled: true,
  navRedesignEnabled: false,
}

const remoteConfigDefaults: BackOfficeRemoteConfig = { ...featureFlagDefaults }

const RemoteConfigContext = React.createContext({
  remoteConfig: remoteConfigDefaults,
  hasFetched: false,
})

const isFeatureFlag = (value: string): value is keyof BackOfficeFeatureFlag => {
  return Object.keys(featureFlagDefaults).includes(value)
}

const RemoteConfigProvider = (props: React.PropsWithChildren<{}>) => {
  const [remoteConfig, setRemoteConfig] =
    React.useState<BackOfficeRemoteConfig>(remoteConfigDefaults)
  const [hasFetched, setHasFetched] = React.useState<boolean>(false)

  React.useEffect(() => {
    FirebaseService.RemoteConfig.defaultConfig = remoteConfigDefaults

    fetchAndActivate(FirebaseService.RemoteConfig)
      .then(() => {
        const config: BackOfficeRemoteConfig = { ...remoteConfigDefaults }
        const values = getAll(FirebaseService.RemoteConfig)
        for (const key of Object.keys(values)) {
          const value = values[key]
          const configKey = snakeCaseToCamelCase(
            key
          ) as keyof BackOfficeRemoteConfig
          if (isFeatureFlag(configKey)) {
            config[configKey] = value.asBoolean()
          } else {
            config[configKey] = value.asString()
          }
        }
        setRemoteConfig(config)
        setHasFetched(true)
      })
      .catch((error) => console.error(error))

    return () => {}
  }, [])

  return (
    <RemoteConfigContext.Provider value={{ remoteConfig, hasFetched }}>
      {props.children}
    </RemoteConfigContext.Provider>
  )
}

export const useRemoteConfig = () => React.useContext(RemoteConfigContext)

export default RemoteConfigProvider
