export function checkRenderPermissions(str, permissions) {
  if (permissions) {
    for (var i = 0, j = permissions.length; i < j; i++) {
      for (var key in permissions[i]) {
        if (permissions[i][key] === str) {
          return true
        }
      }
    }
  }

  return false
}

export function checkUserRenderPermissions(str) {
  const userpolicies = localStorage.getItem('userpolicies')
  return checkRenderPermissions(str, JSON.parse(userpolicies))
}

export function checkAllRenderPermissions(
  requiredPermissions,
  heldPermissions
) {
  for (const permission of requiredPermissions) {
    if (!checkRenderPermissions(permission, heldPermissions)) {
      return false
    }
  }
  return true
}

export function checkAnyRenderPermissions(
  expectedPermissions,
  heldPermissions
) {
  if (expectedPermissions.length === 0) {
    return true
  }
  for (const permission of expectedPermissions) {
    if (checkRenderPermissions(permission, heldPermissions)) {
      return true
    }
  }
  return false
}

export async function sleep(ms) {
  await new Promise((r) => setTimeout(r, ms))
}
