import * as React from 'react'
import { PropsWithChildren } from 'react'
import { NavigationBarContainer } from '../components/NavigationBar/navigation-bar-container'
import './styles.scss'

type BasePageProps = {
  storesObject: any
  props: any
}

const ROOT_CLASS = 'base-page'

export const BasePage = (props: PropsWithChildren<BasePageProps>) => {
  return (
    <div className={`${ROOT_CLASS}-container`}>
      <NavigationBarContainer storesObject={props.storesObject} />
      <div className={ROOT_CLASS}>{props.children}</div>
    </div>
  )
}
