import React, { PropsWithChildren } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { AllPlayslipsStepDownPermissions } from '../../api/playslips-fetcher/permissions'
import { PromotionTabPermissions } from '../../api/promotions-fetcher/permissions'
import { pageMessageDefault } from '../../components/CommonComponents/PageMessageCommonComponent'
import { PageMessageCommonContextComponent } from '../../components/CommonComponents/PageMessageCommonContextComponent'
import HeaderComponent from '../../components/HomeComponent/HeaderComponent'
import MenuContainerComponent from '../../components/HomeComponent/MenuComponent/MenuContainerComponent'
import {
  actionsRoutePath,
  administrationRoutePath,
  annuitiesRoutePath,
  charitableRoutePath,
  claimsSupportRoutePath,
  forbiddenRoutePath,
  knowledgeRoutePath,
  operationsBasePath,
  paymentsRoutePath,
  welcomePageRoutePath,
  playerSupportRoutePath,
  promotionsBasePath,
  reportingRoutePath,
  servicesRoutePath,
  ticketSearchRoutePath,
} from '../../config.js'
import { permissions } from '../../constants.js'
import { checkRenderPermissions } from '../../helpers.js'
import { PrivateRoute } from '../../routes.js'
import { userHasAnyOfPermissions } from '../../util/access-helpers'
import ActionsRoutesPage from '../ActionsPages/ActionsRoutesPage'
import AdministrationsRoutesPage from '../AdministrationsPages/AdministrationsRoutesPage'
import AnnuitiesRoutesPage from '../AnnuitiesPages/AnnuitiesRoutes'
import CharitableRoutesPage from '../CharitableGamingPages/CharitableGamingRoutesPage'
import ClaimsSupportRoutesPage from '../ClaimsPages/ClaimsSupportRoutesPage'
import { Forbidden409Page } from '../CommonsPage/Forbidden409Page'
import { NotFound404Page } from '../CommonsPage/NotFound404Page'
import KnowledgeRoutesPage from '../KnowledgePages/KnowledgeRoutesPage'
import { OperationsRouter } from '../OperationsPages/operations-router'
import PaymentsRoutesPage from '../PaymentsPages/PaymentsRoutesPage'
import PlayersSupportRoutesPage from '../PlayersPages/PlayersSupportRoutesPage'
import { PromotionsRouter } from '../PromotionsPages/promotions-router'
import ReportingRoutesPage from '../ReportingPages/ReportingRoutesPage'
import ServiceRoutesPage from '../ServicePages/ServiceRoutesPage'
import TicketSearchRoutesPage from '../TicketSearch/TicketSearchRoutesPage'
import { useRemoteConfig } from '../../context/RemoteConfigContext'
import { BasePage } from '../base-page'
import { AccessDeniedPageUI } from '../AccessDeniedPage/access-denied-page-ui'
import { WelcomePageContainer } from '../WelcomePage/welcome-page-container'
import styles from './css/styles.module.css'

type HomePageProps = {
  storesObject: any
  props: any
  navRedesignEnabled: boolean
}

const PageWrapper = (props: PropsWithChildren<HomePageProps>) => {
  if (props.navRedesignEnabled) {
    return (
      <BasePage storesObject={props.storesObject} props={props.props}>
        {props.children}
      </BasePage>
    )
  } else {
    return (
      <>
        <div
          onClick={() => {
            pageMessageDefault.message = ''
            pageMessageDefault.messageType = ''
          }}
          className={styles['page-container']}
        >
          <HeaderComponent />
          <div className={styles['layout-new-body-container']}>
            <MenuContainerComponent
              props={props.props}
              storesObject={props.storesObject}
            />
            <div className={styles['layout-new-body-pages']}>
              {props.children}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const HomePage = ({ props, storesObject }: any) => {
  const userpolicies: any = localStorage.getItem('userpolicies')

  const { remoteConfig } = useRemoteConfig()
  const { navRedesignEnabled } = remoteConfig

  const forbiddenPage = navRedesignEnabled ? (
    <AccessDeniedPageUI />
  ) : (
    <Forbidden409Page />
  )

  return (
    <PageWrapper
      storesObject={storesObject}
      props={props}
      navRedesignEnabled={navRedesignEnabled}
    >
      <PageMessageCommonContextComponent />
      <Switch>
        <PrivateRoute
          path={welcomePageRoutePath}
          component={() =>
            navRedesignEnabled ? <WelcomePageContainer /> : forbiddenPage
          }
        />
        <PrivateRoute
          path={`${playerSupportRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
              JSON.parse(userpolicies)
            ) ? (
              <PlayersSupportRoutesPage
                storesObject={storesObject}
                props={props}
              />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={`${actionsRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_ACTIONS,
              JSON.parse(userpolicies)
            ) ? (
              <ActionsRoutesPage storesObject={storesObject} props={props} />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={`${claimsSupportRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
              JSON.parse(userpolicies)
            ) ? (
              <ClaimsSupportRoutesPage
                storesObject={storesObject}
                props={props}
              />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={`${reportingRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_PLAYER_SUPPORT_NEW,
              JSON.parse(userpolicies)
            ) ? (
              <ReportingRoutesPage storesObject={storesObject} props={props} />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={`${annuitiesRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_ANNUITIES,
              JSON.parse(userpolicies)
            ) ? (
              <AnnuitiesRoutesPage storesObject={storesObject} props={props} />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={`${charitableRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_CHARITABLE_GAMING,
              JSON.parse(userpolicies)
            ) ? (
              <CharitableRoutesPage storesObject={storesObject} props={props} />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={promotionsBasePath}
          component={(props: any) =>
            userHasAnyOfPermissions(PromotionTabPermissions) ? (
              <PromotionsRouter />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={operationsBasePath}
          component={(props: any) =>
            userHasAnyOfPermissions(AllPlayslipsStepDownPermissions) ? (
              <OperationsRouter />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={`${administrationRoutePath}`}
          component={(props: any) => (
            <AdministrationsRoutesPage
              storesObject={storesObject}
              props={props}
            />
          )}
        />

        <PrivateRoute
          path={`${ticketSearchRoutePath}`}
          component={(props: any) =>
            checkRenderPermissions(
              permissions.CAN_SEE_TICKETS_NEW,
              JSON.parse(userpolicies)
            ) ? (
              <TicketSearchRoutesPage
                storesObject={storesObject}
                props={props}
              />
            ) : (
              forbiddenPage
            )
          }
        />

        <PrivateRoute
          path={`${paymentsRoutePath}`}
          component={() =>
            checkRenderPermissions(
              permissions.CAN_SEE_GLOBAL_PAYMENTS_MODULE,
              JSON.parse(userpolicies)
            ) ? (
              <PaymentsRoutesPage />
            ) : (
              forbiddenPage
            )
          }
        />
        <PrivateRoute
          path={`${knowledgeRoutePath}`}
          component={(props: any) => (
            <KnowledgeRoutesPage storesObject={storesObject} props={props} />
          )}
        />
        <PrivateRoute
          path={`${servicesRoutePath}`}
          component={(props: any) => (
            <ServiceRoutesPage storesObject={storesObject} props={props} />
          )}
        />
        <PrivateRoute
          path={`${forbiddenRoutePath}`}
          component={(props: any) => forbiddenPage}
        />

        <PrivateRoute
          path={`/`}
          component={(props: any) => <NotFound404Page />}
        />
      </Switch>
    </PageWrapper>
  )
}

export default withRouter(HomePage)
