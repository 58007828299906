import axios from 'axios'

// local
const config = {
  SERVER_BASE_URL: process.env.SERVER_BASE_URL,
}

axios.defaults.withCredentials = true

export const claimsSupportRoutePath = '/new-layout/claims'
export const playerSupportRoutePath = '/new-layout/player-support-new'
export const actionsRoutePath = '/new-layout/actions'
export const servicesRoutePath = '/new-layout/services'
export const reportingRoutePath = '/new-layout/reporting'
export const administrationRoutePath = '/new-layout/administration'
export const knowledgeRoutePath = '/new-layout/knowledge'
export const ticketSearchRoutePath = '/new-layout/ticketSearch'
export const annuitiesRoutePath = '/new-layout/annuities'

//---- charitable games routes - imran 04/18/2022
export const charitableRoutePath = '/new-layout/charitable-gaming'
export const licenseeRoutePath = `${charitableRoutePath}/license`
export const permitRoutePath = `${charitableRoutePath}/permit`
export const pulltabRoutePath = `${charitableRoutePath}/pull-tab`
export const taxformRoutePath = `${charitableRoutePath}/tax-form`
export const chargeRoutePath = `${charitableRoutePath}/charge`
export const CGReportingRoutePath = `${charitableRoutePath}/reports`

// Payments
export const paymentsRoutePath = '/new-layout/payments'
export const paymentsSearchRoutePath = `${paymentsRoutePath}/search`
export const w2gQueueRoutePath = `${paymentsRoutePath}/w2g-queue`
export const checkRunQueueRoutePath = `${paymentsRoutePath}/check-run`
export const futurePaymentsRoutePath = `${paymentsRoutePath}/future`
export const reissueQueueRoutePath = `${paymentsRoutePath}/reissue-queue`
export const futureReissuePaymentsRoutePath = `${paymentsRoutePath}/reissue/future`
export const reissueHistoryRoutePath = `${paymentsRoutePath}/reissue-history`
export const agent1099QueueRoutePath = `${paymentsRoutePath}/agent-1099-queue`
export const agent1099HistoryRoutePath = `${agent1099QueueRoutePath}/history`

// Promotions
export const promotionsBasePath = '/new-layout/promotions'
export const campaignsRoutePath = `${promotionsBasePath}/campaigns`
export const winnersManagementRoutePath = `${promotionsBasePath}/winners-management`
export const promotionMessagingRoutePath = `${promotionsBasePath}/playslip-promotion-messaging`
export const promotionCampaignBuilderRoutePath = `${promotionsBasePath}/promotion-campaign-builder`

// Operations
export const operationsBasePath = `/new-layout/operations`
export const playslipsStepDownRoutePath = `${operationsBasePath}/playslips-step-down`

// Home
export const welcomePageRoutePath = `/new-layout/welcome`

export const forbiddenRoutePath = `/new-layout/forbidden`

export default config
