import React from 'react'
import './Dashboard.css'
import { keyPressedType } from '../services/Commons'

class CustomCard extends React.Component {
  render() {
    const { title, iconPath, handleCallback } = this.props

    return (
      <div
        tabIndex={0}
        className="dashboard-card"
        onClick={handleCallback}
        onKeyDown={(ev) => {
          if (keyPressedType(ev) === 13 || keyPressedType(ev) === 32) {
            handleCallback()
          }
        }}
      >
        {iconPath && (
          <div className="dashboard-card-icon">
            {' '}
            <img src={iconPath} />
          </div>
        )}
        <div className="dashboard-card-label">{title}</div>
      </div>
    )
  }
}

export default CustomCard
