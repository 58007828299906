import * as React from 'react'
import { PromotionDrawingTier } from '../../../../api/promotions-fetcher/constants'
import { getBlockClass } from '../../../../utilities/helpers'
import { WinnersManagementListRowUI } from './winners-management-list-row-ui'
import './styles.scss'
import { TAXABLE_PRIZE_AMOUNT_IN_CENTS } from '../constants'

export type WinnersManagementListUIProps = {
  showActionMenu: boolean
  showOffsets: boolean
  tierData: PromotionDrawingTier
}

const ConstantTableHeaders = [
  { key: 'Prize', column_text: 'Prize' },
  { key: 'First_and_Last_Name', column_text: 'First and Last Name' },
  { key: 'Address', column_text: 'Address' },
  { key: 'City_Town', column_text: 'City/Town' },
  { key: 'State', column_text: 'State' },
  { key: 'Zip', column_text: 'Zip' },
  { key: 'Claim_Num', column_text: 'Claim #' },
  { key: 'Offsets', column_text: 'Offsets' },
  { key: 'Claim_Accepted_Date', column_text: 'Claim Accepted Date' },
]
const OptionsMenuTableHeader = [{ key: 'Options Menu', column_text: '' }]
const SSNTableHeader = [{ key: 'Ssn', column_text: 'SSN' }]
const ROOT_CLASS = 'winners-management-list'
const HEADER_CLASS = `${ROOT_CLASS}-header`

export const WinnersManagementListUI = (
  props: WinnersManagementListUIProps
) => {
  let TableHeaders = ConstantTableHeaders

  if (props.tierData.prizeValueInCents >= TAXABLE_PRIZE_AMOUNT_IN_CENTS) {
    TableHeaders = TableHeaders.concat(SSNTableHeader)
  }
  if (props.showActionMenu) {
    TableHeaders = TableHeaders.concat(OptionsMenuTableHeader)
  }

  return (
    <>
      <table className={ROOT_CLASS}>
        <thead className={HEADER_CLASS}>
          <tr>
            {TableHeaders.map((header) => (
              <th
                className={getBlockClass(HEADER_CLASS, 'cell')}
                key={header.key}
              >
                {header.column_text}
              </th>
            ))}
          </tr>
        </thead>
        {props.tierData.winners
          .sort((a, b) => Number(a.claimNumber) - Number(b.claimNumber))
          .map((row) => (
            <WinnersManagementListRowUI
              key={row.id}
              winner={row}
              prizeAmountInCents={props.tierData.prizeValueInCents}
              prizeType={props.tierData.prizeType}
              showSSNColumn={
                props.tierData.prizeValueInCents >=
                TAXABLE_PRIZE_AMOUNT_IN_CENTS
              }
              showActionMenu={props.showActionMenu}
              showOffsets={props.showOffsets}
            />
          ))}
      </table>
    </>
  )
}
