import React from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { PrivateRoute } from '../../../routes.js'
import RoleManagementComponent from '../../../roles/index.js'
import UserManagementComponent from '../../../users/index.js'
import AdministrationButtons from './../AdministrationButtons'
import { knowledgeRoutePath } from '../../../config.js'
import ActionAuditComponent from '../../../users/ActionAudit/index.js'
import SettingsHubComponent from '../../../Admin/Settings/Hub/index.js'
import KnowledgeBasePage from './../KnowledgeBasePage'
import AuthLayoutHOC from '../../../Layout/AuthLayoutHOC.js'

const KnowledgeRoutesPage = ({ props, storesObject }: any) => {
  const playerId: string = `${
    props.location.pathname.split('/')[
      props.location.pathname.split('/').length - 2
    ]
  }`
  const claimsId: string = `${
    props.location.pathname.split('/')[
      props.location.pathname.split('/').length - 1
    ]
  }`
  const urlVariables: string = `${playerId}/${claimsId}`

  return (
    <>
      <Switch>
        <PrivateRoute
          path={`${knowledgeRoutePath}`}
          component={(props: any) => (
            <KnowledgeBasePage
              title="Knowledge"
              {...props}
              showBackButton={true}
            />
          )}
        />
      </Switch>
    </>
  )
}

export default withRouter(KnowledgeRoutesPage)
