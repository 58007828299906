import React, { useState } from 'react'
import styles from './css/styles.module.css'
import admin from './../../../../icons/admin-icon.svg'
import knowledge from './../../../../icons/knowledge-icon.svg'
import { keyPressedType } from '../../../../services/Commons'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clearPageMessageDefault } from './../../../../components/CommonComponents/PageMessageCommonComponent'
import {
  administrationRoutePath,
  knowledgeRoutePath,
} from './../../../../config.js'
import { useHistory } from 'react-router'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { checkRenderPermissions } from '../../../../helpers'
import { permissions } from '../../../../constants'

const defaultButtonStates: any = {
  admin: 'false',
  knowledge: 'false',
}
const MenuAdministrationComponent = ({ props, storesObject }: any) => {
  const history = useHistory()
  const userPolicies: string | any = localStorage.getItem('userpolicies')

  const [buttonStates, setButtonStates] = useState<any>(defaultButtonStates)
  const updateButtonStates = (e: any) => {
    Object.keys(defaultButtonStates).forEach((key: any) => {
      if (key === e) buttonStates[key] = 'true'
      else buttonStates[key] = 'false'
    })

    setButtonStates({ ...buttonStates })
  }
  return (
    <>
      <div
        role="nav"
        className={styles['menu-administration-component-container']}
      >
        {checkRenderPermissions(
          permissions.CAN_SEE_ADMIN_MENU,
          JSON.parse(userPolicies)
        ) ? (
          <div
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${administrationRoutePath}`)
                clearPageMessageDefault()
                updateButtonStates('admin')
              }
            }}
            onClick={() => {
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${administrationRoutePath}`)
              clearPageMessageDefault()
              updateButtonStates('admin')
            }}
            aria-live={'assertive'}
            role="button"
            id={'admin'}
            tabIndex={0}
            aria-pressed={defaultButtonStates['admin']}
            title={'Admin'}
            className={styles['menu-administration-icons-contanier']}
          >
            <img
              src={admin}
              className={styles['menu-administration-icon']}
              alt={'Admin'}
            />
            <div className={styles['menu-administration-icon-text']}>Admin</div>
          </div>
        ) : null}
        {checkRenderPermissions(
          permissions.CAN_SEE_KNOWLEDGE_BASE,
          JSON.parse(userPolicies)
        ) ? (
          <div
            aria-live={'assertive'}
            role="button"
            id={'knowledge'}
            tabIndex={0}
            aria-pressed={defaultButtonStates['knowledge']}
            title={'Knowledge'}
            onKeyPress={(e: any) => {
              if (keyPressedType(e) === 13 || keyPressedType(e) === 0) {
                storesObject.playerStore.reset()
                storesObject.claimStore.reset()
                history.push(`${knowledgeRoutePath}`)
                clearPageMessageDefault()
                updateButtonStates('knowledge')
              }
            }}
            onClick={() => {
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              history.push(`${knowledgeRoutePath}`)
              clearPageMessageDefault()
              updateButtonStates('knowledge')
            }}
            className={styles['menu-administration-icons-contanier']}
          >
            <img
              src={knowledge}
              className={styles['menu-administration-icon']}
              alt={'knowledge'}
            />
            <div className={styles['menu-administration-icon-text']}>
              Knowledge
            </div>
          </div>
        ) : null}
        <div
          style={{ marginTop: '3em' }}
          aria-live={'assertive'}
          role="button"
          id="actions"
          tabIndex={0}
          aria-pressed={buttonStates['actions']}
          title={'Actions'}
          onKeyPress={(e: any) => {
            if (
              keyPressedType(e) === 13 ||
              keyPressedType(e) === 0 ||
              keyPressedType(e) === 32
            ) {
              clearPageMessageDefault()
              storesObject.playerStore.reset()
              storesObject.claimStore.reset()
              storesObject.authStore.logout(props)
            }
          }}
          onClick={(e: any) => {
            clearPageMessageDefault()
            storesObject.playerStore.reset()
            storesObject.claimStore.reset()
            storesObject.authStore.logout(props)
            updateButtonStates('logout')
          }}
          className={styles['menu-administration-icons-contanier']}
        >
          <FontAwesomeIcon
            icon={faSignOutAlt as IconProp}
            color={'rgb(23, 62, 103)'}
          />
          <div className={styles['menu-administration-icon-text']}>Log Out</div>
        </div>
      </div>
    </>
  )
}

export default MenuAdministrationComponent
