import {
  playerSupportRoutePath,
  administrationRoutePath,
  paymentsSearchRoutePath,
  chargeRoutePath,
  campaignsRoutePath,
} from '../../config.js'
import { userHasAnyOfPermissions } from '../../util/access-helpers'

export type NavLink = {
  displayName: string
  destination: string
  disabled: boolean // used if on page currently, should gray out link if so
  requiredPermissions: string[] // used to determine if link should be shown at all
}

export type NavModule = {
  title: string
  categories: NavCategory[]
}

export type NavCategory = {
  title: string
  links: NavLink[]
}

// TO DO: change on follow up stories to appropriate modules/categories/links and permissions
export const navigationModules: NavModule[] = [
  {
    title: 'Players',
    categories: [
      {
        title: 'Players',
        links: [
          {
            displayName: 'Player Search',
            destination: `${playerSupportRoutePath}/search-new`,
            disabled: false,
            requiredPermissions: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Finance',
    categories: [
      {
        title: 'Payments',
        links: [
          {
            displayName: 'Payment Search & Management',
            destination: paymentsSearchRoutePath,
            disabled: false,
            requiredPermissions: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Charitable Gaming',
    categories: [
      {
        title: 'Charitable Gaming',
        links: [
          {
            displayName: 'Charge Management',
            destination: chargeRoutePath,
            disabled: false,
            requiredPermissions: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Promotions',
    categories: [
      {
        title: 'Promotions',
        links: [
          {
            displayName: 'Campaign Builder',
            destination: campaignsRoutePath,
            disabled: false,
            requiredPermissions: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Tools',
    categories: [
      {
        title: 'Tools',
        links: [
          {
            displayName: 'Frequent Casher Module',
            destination: `${administrationRoutePath}/frequent-casher`,
            disabled: false,
            requiredPermissions: [],
          },
        ],
      },
    ],
  },
  {
    title: 'Settings',
    categories: [
      {
        title: 'Settings',
        links: [
          {
            displayName: 'Log Out',
            destination: 'logout',
            disabled: false,
            requiredPermissions: [],
          },
        ],
      },
    ],
  },
]

export const checkCurrentPageModule = (
  module: NavModule,
  currentPath: string
) => {
  return module.categories.some((category) =>
    category.links.some((link) => currentPath.startsWith(link.destination))
  )
}

export const checkModulePermissions = (module: NavModule) => {
  return (
    module.categories.filter((category) =>
      category.links.some((link) =>
        userHasAnyOfPermissions(link.requiredPermissions)
      )
    ).length > 0
  )
}

export const checkCategoryPermissions = (category: NavCategory) => {
  return category.links.some((link) =>
    userHasAnyOfPermissions(link.requiredPermissions)
  )
}

export const checkLinkPermissions = (link: NavLink) => {
  return userHasAnyOfPermissions(link.requiredPermissions)
}
