import * as React from 'react'
import config from '../../../config'
import { LandingPageUI } from './landing-page-ui'
import { inject, observer } from 'mobx-react'
import { useFetch } from '../../../hooks/use-fetch'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { useRemoteConfig } from '../../../context/RemoteConfigContext'

export const LandingPageHOC = inject('authStore')(
  observer((authStore: { authStore: any }) => LandingPageContainer(authStore))
)

const USER_INFO_URL = `${config.SERVER_BASE_URL}/v1/user/info`

const LandingPageContainer = (props: { authStore: any }) => {
  const { authStore } = props
  const history = useHistory()
  const { fetchUrl, data, requestSuccessful, error } = useFetch<{
    firstname: string
    lastname: string
    userid: string
    roleid: string
  }>()

  const { remoteConfig, hasFetched } = useRemoteConfig()
  const { navRedesignEnabled } = remoteConfig

  React.useEffect(() => {
    fetchUrl(USER_INFO_URL, 'GET')
  }, [])

  React.useEffect(() => {
    if (data && requestSuccessful && hasFetched) {
      authStore.handleUserInfo(data, props, navRedesignEnabled)
    }
  }, [requestSuccessful, data, hasFetched])

  React.useEffect(() => {
    if (error) {
      history.push('/login')
    }
  }, [error])
  return (
    <LandingPageUI>
      <CircularProgress style={{ color: 'white', margin: '16px' }} />
    </LandingPageUI>
  )
}
