import * as React from 'react'
import { getBlockClass } from '../../utilities/helpers'
import './styles.scss'

const ROOT_CLASS = 'access-denied-page'

export const AccessDeniedPageUI = () => {
  return (
    <div className={ROOT_CLASS}>
      <h1 className={getBlockClass(ROOT_CLASS, 'header')}>Access Denied</h1>
      <p className={getBlockClass(ROOT_CLASS, 'message')}>
        Please contact a system administrator to be granted the permission
        required to access this page.
      </p>
    </div>
  )
}
