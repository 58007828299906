import * as React from 'react'
import FocusLock from 'react-focus-lock'
import { useLocation } from 'react-router-dom'
import { MassLotteryLogo } from '../../icons/mass-lottery-logo'
import { welcomePageRoutePath } from '../../config.js'
import {
  getBlockClass,
  getModifierClass,
  joinClasses,
} from '../../utilities/helpers'
import { SearchIcon } from '../../icons/search-icon'
import { SettingsIcon } from '../../icons/settings-icon'
import { useKeyDown } from '../../hooks/use-keydown/use-keydown'
import { AccessibleKey } from '../common'
import {
  NavModule,
  checkCurrentPageModule,
  checkModulePermissions,
} from './helpers'
import { NavigationMenu } from './NavigationMenu/navigation-menu'
import './styles.scss'

type NavigationBarUIProps = {
  modules: NavModule[]
  navigateToPage: (path: string) => void
  handleLogout: () => void
}

const ROOT_CLASS = 'navigation-bar'
const BUTTON_GROUP_CLASS = getBlockClass(ROOT_CLASS, 'button-group')
const BUTTON_CLASS = getBlockClass(ROOT_CLASS, 'button')
const SMALL_BUTTON_CLASS = getModifierClass(BUTTON_CLASS, 'small', true)

export const NavigationBarUI = (props: NavigationBarUIProps) => {
  const { pathname } = useLocation()

  const [openModule, setOpenModule] = React.useState<NavModule>()

  const closeMenu = () => setOpenModule(undefined)

  // Close menu upon choosing a page (or using browser back/forward)
  React.useEffect(() => {
    closeMenu()
  }, [pathname])

  const handleKeyDown = () => {
    if (openModule) {
      closeMenu()
    }
  }
  useKeyDown({
    key: AccessibleKey.escape,
    action: handleKeyDown,
    dependencies: [openModule],
  })

  const handleModuleClick = (module?: NavModule) => {
    // If you click on the currently open module, should close the menu
    if (openModule?.title === module?.title) {
      closeMenu()
    } else {
      setOpenModule(module)
    }
  }

  return (
    <nav>
      <FocusLock disabled={!openModule} autoFocus={false}>
        <div className={ROOT_CLASS}>
          <div className={BUTTON_GROUP_CLASS}>
            <button
              className={getBlockClass(ROOT_CLASS, 'logo')}
              onClick={() => props.navigateToPage(welcomePageRoutePath)}
              aria-label="Navigate to Home Page"
            >
              <MassLotteryLogo />
            </button>
          </div>
          <div className={BUTTON_GROUP_CLASS}>
            {props.modules
              .filter(
                (module) =>
                  checkModulePermissions(module) && module.title !== 'Settings'
              )
              .map((module) => (
                // If menu is closed, white underline goes under current page module
                // Otherwise goes under current open menu module
                <button
                  key={`${module.title}-menu-button`}
                  className={joinClasses([
                    BUTTON_CLASS,
                    getModifierClass(
                      BUTTON_CLASS,
                      'selected',
                      openModule
                        ? openModule.title === module.title
                        : checkCurrentPageModule(module, pathname)
                    ),
                  ])}
                  onClick={() => handleModuleClick(module)}
                  aria-label={`Open ${module.title} Menu`}
                >
                  {module.title}
                </button>
              ))}
          </div>
          <div className={BUTTON_GROUP_CLASS}>
            <button className={SMALL_BUTTON_CLASS} aria-label="Search">
              {/* For later story: update to grey when search is open (see Settings button) */}
              <SearchIcon color={'white'} />
            </button>
            <button
              className={SMALL_BUTTON_CLASS}
              onClick={() =>
                handleModuleClick(
                  props.modules.find((module) => module.title === 'Settings')
                )
              }
              aria-label="Open Settings Menu"
            >
              <SettingsIcon
                color={openModule?.title === 'Settings' ? '#ABABAB' : 'white'}
              />
            </button>
          </div>
        </div>
        <NavigationMenu
          isOpen={openModule !== undefined}
          currentPath={pathname}
          module={openModule}
          closeMenu={closeMenu}
          navigateToPage={props.navigateToPage}
          handleLogout={props.handleLogout}
        />
        {openModule && (
          <div className={`${ROOT_CLASS}__overlay`} onClick={closeMenu} />
        )}
      </FocusLock>
    </nav>
  )
}
