import { checkRenderPermissions } from './../helpers.js'

export function userHasAnyOfPermissions(permissions: string[]) {
  const userPolicies = localStorage.getItem('userpolicies') ?? '{}'

  if (permissions.length === 0) {
    return true
  }

  for (const permission of permissions) {
    if (checkRenderPermissions(permission, JSON.parse(userPolicies))) {
      return true
    }
  }
  return false
}
